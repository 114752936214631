import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { FaTwitter, FaGithub, FaFacebook, FaLinkedin } from "react-icons/fa";

export function SideBar() {
    const [randomImage, setRandomImage] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        // Set a random image on initial load
        setRandomImage(getRandomImage());
    }, []);

    function getRandomImage() {
        let rand = Math.floor(Math.random() * 10);
        let imageNum = rand + 1;
        
        // Use a direct path to images in the public folder
        return `/images/amith_gc${imageNum}.png`;
    }

    const toggleMenu = () => {
        const newMenuState = !menuOpen;
        setMenuOpen(newMenuState);
        
        // Apply the 'open' class to the sidebar itself for mobile view
        const sidebarElement = document.getElementById('sidebar-modern');
        if (sidebarElement) {
            if (newMenuState) {
                sidebarElement.classList.add('open');
                document.body.classList.add('menu-open');
            } else {
                sidebarElement.classList.remove('open');
                document.body.classList.remove('menu-open');
            }
        }
    };

    return (
        <>
            <header id="sidebar-modern" className={menuOpen ? 'open' : ''}>
                <div className="mobile-menu-toggle" onClick={toggleMenu}>
                    <Icon name={menuOpen ? "close" : "bars"} size="large" />
                </div>
                
                <div className={`sidebar-content ${menuOpen ? 'open' : ''}`} id="sidebar-content">
                    <div className="profile-section">
                        <div className="profile-image">
                            <a href="/" className="avatar-link">
                                <img 
                                    src={randomImage} 
                                    alt="Amith GC" 
                                    loading="eager" 
                                    style={{
                                        display: 'block',
                                        width: '150px',
                                        height: '150px',
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                        margin: '0 auto',
                                        border: '3px solid rgba(78, 204, 163, 0.8)',
                                        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)'
                                    }}
                                    onError={(e) => {
                                        console.error("Image failed to load", e);
                                        e.target.src = "/images/avatar.jpg";
                                    }}
                                />
                            </a>
                        </div>
                        <div className="profile-info">
                            <h1 className="name">Amith Chandrappa</h1>
                            <div className="title">
                                Engineering Head at <a href="https://www.give.do/" target="_blank" rel="noreferrer">GIVE</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="bio-section">
                        <p>Results-driven engineering leader with 18+ years of experience building high-performing teams across startups and enterprises. Expertise in scalable architecture, cloud infrastructure, and delivering mission-critical products that drive business growth.</p>
                    </div>
                    
                    <div className="social-section">
                        <div className="social-row">
                            <a href="https://twitter.com/amithgc" target="_blank" rel="noreferrer" className="social-icon">
                                <FaTwitter style={{fontSize: '22px'}} />
                            </a>
                            <a href="https://github.com/amithgc?tab=repositories" target="_blank" rel="noreferrer" className="social-icon">
                                <FaGithub style={{fontSize: '22px'}} />
                            </a>
                            <a href="https://facebook.com/amithgc" target="_blank" rel="noreferrer" className="social-icon">
                                <FaFacebook style={{fontSize: '22px'}} />
                            </a>
                        </div>
                        <div className="social-row">
                            <a href="https://linkedin.com/in/amithgc" target="_blank" rel="noreferrer" className="social-icon">
                                <FaLinkedin style={{fontSize: '22px'}} />
                            </a>
                        </div>
                    </div>
                    
                    <div className="copyright-section">
                        <p>&copy; amith.co.in | amithgc.com | Amith GC</p>
                    </div>
                </div>
            </header>
            
            <style jsx="true">{`
                #sidebar-modern {
                    background: linear-gradient(135deg, #1a2a3a, #152536, #0e1c2d);
                    color: #fff;
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 300px;
                    height: 100vh;
                    display: flex;
                    flex-direction: column;
                    z-index: 1000;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
                    overflow-y: auto;
                    scrollbar-width: thin;
                    transition: transform 0.3s ease;
                    border-right: 1px solid rgba(255, 255, 255, 0.05);
                }
                
                /* Override for mobile devices */
                @media (max-width: 768px) {
                    /* Semi-transparent overlay when menu is open */
                    body:before {
                        content: '';
                        display: none;
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0,0.5);
                        z-index: 999;
                    }
                    
                    body.menu-open:before {
                        display: block;
                    }
                }
                
                #sidebar-modern::-webkit-scrollbar {
                    width: 4px;
                }
                
                #sidebar-modern::-webkit-scrollbar-track {
                    background: rgba(255, 255, 255, 0.1);
                }
                
                #sidebar-modern::-webkit-scrollbar-thumb {
                    background: rgba(255, 255, 255, 0.3);
                    border-radius: 2px;
                }
                
                .sidebar-content {
                    display: flex;
                    flex-direction: column;
                    padding: 40px 30px;
                    height: 100%;
                }
                
                .mobile-menu-toggle {
                    display: none;
                    position: fixed;
                    top: 15px;
                    left: 15px;
                    color: white;
                    background: rgba(26, 42, 58, 0.8);
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    z-index: 1002;
                    cursor: pointer;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                    transition: background-color 0.3s ease;
                }

                .mobile-menu-toggle:hover {
                    background: rgba(78, 204, 163, 0.8);
                }
                
                .profile-section {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    margin-bottom: 25px;
                    padding-bottom: 5px;
                    position: relative;
                }
                
                .profile-section::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 40px;
                    height: 2px;
                    background: rgba(78, 204, 163, 0.5);
                    border-radius: 2px;
                }
                
                .profile-image {
                    margin-bottom: 20px;
                    display: flex;
                    justify-content: center;
                    width: 100%;
                }
                
                .avatar-link {
                    display: inline-block;
                    border: none;
                    padding: 0;
                }
                
                .avatar-link:hover {
                    border: none;
                }
                
                .profile-image img {
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    object-fit: cover;
                    border: 3px solid rgba(78, 204, 163, 0.5);
                    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
                    transition: transform 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
                    display: block !important;
                    margin: 0 auto;
                }
                
                .profile-image img:hover {
                    transform: scale(1.05);
                    border-color: rgba(78, 204, 163, 0.8);
                    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);
                }

                .avatar-link {
                    display: block;
                    width: fit-content;
                    margin: 0 auto;
                }
                
                .profile-info {
                    margin-bottom: 15px;
                }
                
                .name {
                    font-size: 24px;
                    font-weight: 600;
                    margin: 0 0 5px 0;
                    color: white;
                    letter-spacing: 0.5px;
                    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
                }
                
                .title {
                    font-size: 15px;
                    color: rgba(78, 204, 163, 0.9);
                    margin-bottom: 15px;
                    letter-spacing: 0.4px;
                    font-weight: 500;
                }
                
                .title a {
                    color: rgba(255, 255, 255, 0.95);
                    border-bottom: 1px dotted rgba(78, 204, 163, 0.7);
                    text-decoration: none;
                    transition: all 0.2s ease;
                    padding-bottom: 1px;
                }
                
                .title a:hover {
                    color: white;
                    border-bottom-color: rgba(78, 204, 163, 1);
                }
                
                .bio-section {
                    margin-bottom: 30px;
                    text-align: center;
                    padding: 0 10px;
                }
                
                .bio-section p {
                    font-size: 14px;
                    line-height: 1.7;
                    color: rgba(255, 255, 255, 0.8);
                    margin: 0;
                    letter-spacing: 0.2px;
                }
                
                .social-section {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 30px;
                }
                
                .social-row {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 16px;
                }
                
                .social-row:last-child {
                    margin-bottom: 0;
                }
                
                .social-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 38px;
                    height: 38px;
                    margin: 0 10px;
                    background: rgba(255, 255, 255, 0.08);
                    border-radius: 50%;
                    color: white;
                    transition: all 0.3s ease;
                    border: 1px solid rgba(78, 204, 163, 0.2);
                    position: relative;
                }
                
                .social-icon svg {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                    font-size: 20px !important;
                }
                
                .social-icon .icon {
                    margin: 0 !important;
                    height: auto !important;
                    font-size: 20px !important;
                }
                
                .social-icon:hover {
                    background: rgba(78, 204, 163, 0.9);
                    color: #0e1c2d;
                    transform: translateY(-3px);
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
                    border: 1px solid rgba(78, 204, 163, 0.6);
                }
                
                .copyright-section {
                    margin-top: auto;
                    text-align: center;
                    font-size: 11px;
                    color: rgba(255, 255, 255, 0.4);
                    padding-top: 20px;
                    letter-spacing: 0.5px;
                }
                
                .copyright-section p {
                    margin: 0;
                    border-top: 1px solid rgba(255, 255, 255, 0.05);
                    padding-top: 15px;
                }
                
                @media (max-width: 1024px) {
                    #sidebar-modern {
                        width: 250px;
                    }
                    
                    .sidebar-content {
                        padding: 30px 20px;
                    }
                    
                    .profile-image img {
                        width: 120px;
                        height: 120px;
                    }
                }
                
                @media (max-width: 768px) {
                    #sidebar-modern {
                        transform: translateX(-100%);
                        width: 100%;
                        max-width: 300px;
                        z-index: 1001;
                        background: #1a2a3a; /* Solid background color as fallback */
                        background: linear-gradient(135deg, #1a2a3a, #152536, #0e1c2d) !important;
                    }
                    
                    #sidebar-modern.open {
                        transform: translateX(0);
                    }
                    
                    .sidebar-content {
                        padding: 80px 20px 30px;
                        height: 100%;
                        overflow-y: auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    
                    .mobile-menu-toggle {
                        display: flex;
                        z-index: 1002;
                    }
                    
                    .profile-image {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        margin: 0 auto 20px auto;
                    }
                    
                    .profile-image img {
                        margin: 0 auto;
                        display: block !important;
                        visibility: visible !important;
                        opacity: 1 !important;
                        max-width: 100%;
                    }
                    
                    .avatar-link {
                        display: block;
                        width: fit-content;
                        margin: 0 auto;
                    }
                }
                
                @media (max-width: 480px) {
                    .profile-image img {
                        width: 120px;
                        height: 120px;
                        margin: 0 auto;
                    }
                    
                    .name {
                        font-size: 20px;
                    }
                    
                    .title {
                        font-size: 14px;
                    }
                    
                    .bio-section p {
                        font-size: 13px;
                    }
                    
                    .social-icon {
                        width: 35px;
                        height: 35px;
                        margin: 0 8px;
                    }

                    #sidebar-modern {
                        overflow-y: auto;
                        max-height: 100vh;
                    }

                    #sidebar-content.open {
                        padding-top: 60px;
                    }
                }
            `}</style>
            
            <style jsx="true" global="true">{`
                #main {
                    margin-left: 300px;
                    width: calc(100% - 300px);
                    transition: margin-left 0.3s ease, width 0.3s ease;
                }
                
                @media (max-width: 1024px) {
                    #main {
                        margin-left: 250px;
                        width: calc(100% - 250px);
                    }
                }
                
                @media (max-width: 768px) {
                    #main {
                        margin-left: 0;
                        width: 100%;
                        padding: 60px 20px 20px 20px;
                    }
                }
            `}</style>
        </>
    );
}
