import React, { useState, useEffect, useCallback } from "react";
import { Icon, Button } from "semantic-ui-react";

export function SkillSet() {
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [filteredSkills, setFilteredSkills] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const filterSkills = useCallback((category) => {
        setSelectedCategory(category);
        
        if (category === "All") {
            setFilteredSkills(data.skills);
        } else {
            const filtered = data.skills.filter(skill => skill.category === category);
            setFilteredSkills(filtered);
        }
    }, [data]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (data && data.skills) {
            // Extract unique categories
            const uniqueCategories = [...new Set(data.skills.map(item => item.category))].sort();
            setCategories(["All", ...uniqueCategories]);
            
            // Apply initial filter
            filterSkills("All");
            setIsLoading(false);
        }
    }, [data, filterSkills]);

    function getData() {
        setIsLoading(true);
        fetch('data/skills.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(function(response) {
            return response.json();
        })
        .then(function(myJson) {
            setData(myJson);
        })
        .catch(function(error) {
            console.error("Error fetching skills data:", error);
            setIsLoading(false);
        });
    }

    function getSkillCard(item) {
        let image = "../images/technologies/" + item.image;
        return (
            <div className="skill-card" key={item.name}>
                <div className="skill-card-inner">
                    <div className="skill-icon">
                        <img src={image} alt={item.name} />
                    </div>
                    <div className="skill-content">
                        <span className="skill-name">{item.name}</span>
                        <span className="skill-category">{item.category}</span>
                    </div>
                </div>
            </div>
        );
    }

    function getCategoryButton(category) {
        return (
            <Button 
                key={category} 
                className={`category-button ${selectedCategory === category ? 'active' : ''}`}
                onClick={() => filterSkills(category)}
            >
                {category}
            </Button>
        );
    }

    return (
        <div id="main">
            <section className="skills-section">
                <h2>Technical Expertise <Icon name="code" /></h2>
                <p>In my journey, I've mastered a diverse set of technologies across various domains.</p>
                
                {isLoading ? (
                    <div className="loading">
                        <Icon name="spinner" loading size="large" />
                        <span>Loading skills...</span>
                    </div>
                ) : (
                    <>
                        <div className="filter-container">
                            <div className="category-buttons">
                                {categories.map(category => getCategoryButton(category))}
                            </div>
                        </div>
                        
                        <div className="skills-container">
                            {filteredSkills && filteredSkills.map(item => getSkillCard(item))}
                            {filteredSkills && filteredSkills.length === 0 && (
                                <div className="no-skills">
                                    <Icon name="search" size="large" />
                                    <p>No skills found in this category</p>
                                </div>
                            )}
                        </div>
                        
                        <div className="skills-count">
                            <span>{selectedCategory === "All" ? "All Skills" : selectedCategory}: {filteredSkills.length} {filteredSkills.length === 1 ? "skill" : "skills"}</span>
                        </div>
                    </>
                )}
            </section>
            <style jsx="true">{`
                .skills-section {
                    background: linear-gradient(145deg, #f8f9fa, #e9ecef);
                    padding: 40px 20px;
                    border-radius: 12px;
                    border: 1px solid #e9ecef;
                    margin-bottom: 40px;
                    position: relative;
                    overflow: hidden;
                }
                h2 {
                    text-align: center;
                    color: #242323;
                    margin-bottom: 20px;
                    font-size: 32px;
                    font-weight: 700;
                    letter-spacing: 0.5px;
                }
                p {
                    text-align: center;
                    color: #555;
                    margin-bottom: 30px;
                    font-size: 16px;
                    max-width: 700px;
                    margin-left: auto;
                    margin-right: auto;
                }
                .loading {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 40px;
                    flex-direction: column;
                    gap: 15px;
                    color: #555;
                }
                .filter-container {
                    margin-bottom: 30px;
                    padding: 0 10px;
                }
                .category-buttons {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 10px;
                    margin-bottom: 20px;
                }
                .category-button {
                    background-color: #f0f2f5 !important;
                    color: #333 !important;
                    border-radius: 20px !important;
                    font-size: 14px !important;
                    padding: 8px 16px !important;
                    transition: all 0.3s ease !important;
                    border: 1px solid transparent !important;
                    margin: 4px !important;
                }
                .category-button:hover {
                    background-color: #e9ecef !important;
                    transform: translateY(-2px);
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }
                .category-button.active {
                    background: linear-gradient(135deg, #4361ee, #3a0ca3) !important;
                    color: white !important;
                    border: 1px solid #4361ee !important;
                    box-shadow: 0 4px 10px rgba(67, 97, 238, 0.3);
                }
                .skills-container {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                    gap: 16px;
                    justify-content: center;
                    padding: 10px 15px;
                    min-height: 200px;
                }
                .skill-card {
                    background-color: white;
                    border-radius: 10px;
                    padding: 0;
                    overflow: hidden;
                    transition: all 0.3s ease;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
                    height: 100%;
                    border: 1px solid #eaeaea;
                }
                .skill-card:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
                    border-color: #4361ee;
                }
                .skill-card-inner {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
                .skill-icon {
                    background: linear-gradient(to bottom, #f8f9fa, #ffffff);
                    padding: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid #f0f0f0;
                }
                .skill-icon img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
                .skill-content {
                    padding: 15px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    flex-grow: 1;
                }
                .skill-name {
                    font-size: 15px;
                    font-weight: 600;
                    color: #333;
                }
                .skill-category {
                    font-size: 12px;
                    color: #666;
                    background-color: #f5f5f5;
                    padding: 3px 8px;
                    border-radius: 10px;
                    display: inline-block;
                    margin-top: 4px;
                    width: fit-content;
                    margin-left: auto;
                    margin-right: auto;
                }
                .no-skills {
                    grid-column: 1 / -1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 40px;
                    color: #888;
                    text-align: center;
                }
                .skills-count {
                    text-align: center;
                    margin-top: 20px;
                    font-size: 14px;
                    color: #666;
                    padding: 8px 15px;
                    background-color: rgba(0, 0, 0, 0.03);
                    border-radius: 20px;
                    display: inline-block;
                    margin-left: 50%;
                    transform: translateX(-50%);
                }
                @media (max-width: 768px) {
                    .skills-container {
                        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
                        gap: 12px;
                    }
                    .skill-card {
                        padding: 0;
                    }
                    .skill-icon {
                        padding: 15px;
                    }
                    .skill-icon img {
                        width: 40px;
                        height: 40px;
                    }
                    .skill-content {
                        padding: 10px 12px;
                    }
                    .skill-name {
                        font-size: 13px;
                    }
                    .skill-category {
                        padding: 2px 8px;
                        max-width: 90%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .category-buttons {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 8px;
                        padding-bottom: 10px;
                        margin: 0;
                        width: 100%;
                    }
                    .category-button {
                        font-size: 12px !important;
                        padding: 6px 8px !important;
                        white-space: nowrap;
                        margin: 0 !important;
                        width: 100%;
                        display: flex !important;
                        justify-content: center;
                        align-items: center;
                    }
                }
                @media (max-width: 480px) {
                    .skills-container {
                        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
                        gap: 10px;
                    }
                    h2 {
                        font-size: 24px;
                    }
                    p {
                        font-size: 14px;
                    }
                    .skill-content {
                        padding: 8px 10px;
                    }
                    .skill-category {
                        font-size: 11px;
                        padding: 2px 6px;
                    }
                }
            `}</style>
        </div>
    );
}
