import React from "react";
import {SideBar} from "./SideBar";
import {Introduction} from "./Introduction";
import {SkillSet} from "./professional/SkillSet";
import {WorkExperience} from "./professional/WorkExperience";
import {HobbyProjects} from "./prsnl/HobbyProjects";
import {OpenSourceProjects} from "./prsnl/OpenSourceProjects";
import { BlogsList } from "./blog/BlogsList";
import { StoriesList } from "./stories/StoriesList";

export function MainPage() {
    return (
        <div>
            <SideBar/>

            <Introduction/>
            <SkillSet/>

            <WorkExperience/>

            {/* <AllArtProjects heading={"Recent Art Projects"} recent={true}/> */}
            <HobbyProjects/>
            <OpenSourceProjects/>
            <BlogsList />
            <StoriesList />
            {/*<Activity/>*/}

        </div>
    );
}
