import React, { useState, useEffect } from "react";
import { Icon, Label } from "semantic-ui-react";

export function Introduction() {
  const [introData, setIntroData] = useState({
    header: "",
    paragraphs: [],
    blogLink: { text: "", url: "" }
  });

  useEffect(() => {
    // Fetch the introduction data from the JSON file
    fetch('/data/introduction.json')
      .then(response => response.json())
      .then(data => setIntroData(data))
      .catch(error => console.error('Error loading introduction data:', error));
  }, []);

  function getExperience() {
    let dob = "20050801";
    let year = Number(dob.substr(0, 4));
    let month = Number(dob.substr(4, 2)) - 1;
    let day = Number(dob.substr(6, 2));
    let today = new Date();
    let age = today.getFullYear() - year;
    if (
      today.getMonth() < month ||
      (today.getMonth() === month && today.getDate() < day)
    ) {
      age--;
    }

    return age;
  }

  // Function to replace {experience} placeholder with actual experience
  const renderParagraph = (paragraph) => {
    return paragraph.replace('{experience}', getExperience().toString());
  };

  return (
    <div id="main" style={{ fontSize: 18, overflowX: "hidden" }}>
      <section style={{ width: "100%", overflowX: "hidden", maxWidth: "100%" }}>
        <header
          className="major"
          style={{ width: "100%", maxWidth: "100%" }}
        >
          <h2 style={{ textAlign: "left" }}>{introData.header}</h2>
        </header>
        <br />

        <div style={{ width: "100%", maxWidth: "100%", overflowWrap: "break-word" }}>
          {introData.paragraphs.map((paragraph, index) => (
            <p 
              key={index} 
              style={{ 
                textAlign: "justify", 
                width: "100%", 
                paddingRight: 0,
                boxSizing: "border-box",
                maxWidth: "100%",
                overflowWrap: "break-word",
                wordWrap: "break-word",
                wordBreak: "normal",
                hyphens: "auto"
              }}
              dangerouslySetInnerHTML={{ __html: renderParagraph(paragraph) }}
            />
          ))}
        </div>

        <Label style={{ margin: 5, padding: 10 }}>
          <a rel="noreferrer" target="_blank" href={introData.blogLink.url}>
            {introData.blogLink.text}&nbsp;&nbsp;
            <Icon name="right arrow" />
          </a>
        </Label>
      </section>
    </div>
  );
}
