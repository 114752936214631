import React from "react";
import {Item, Icon} from 'semantic-ui-react'
import Header from "semantic-ui-react/dist/commonjs/elements/Header";

export function HobbyProjects() {

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        getData()
    }, []);

    function getData() {
        fetch('data/hobby-projects.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson)
            });
    }

    // Helper functions for determining link types removed as they're no longer needed

    // Old website button function removed

    function getTypeLabel(type) {
        let buttonText, buttonIcon;
        
        switch(type.toLowerCase()) {
            case 'android game':
                buttonText = 'ANDROID GAME';
                buttonIcon = 'gamepad';
                break;
            case 'website':
            case 'web':
                buttonText = 'Website';
                buttonIcon = 'code';
                break;
            case 'mobile app':
                buttonText = 'MOBILE APP';
                buttonIcon = 'mobile alternate';
                break;
            case 'mobile':
                buttonText = 'MOBILE';
                buttonIcon = 'mobile alternate';
                break;
            case 'desktop':
                buttonText = 'DESKTOP';
                buttonIcon = 'computer';
                break;
            case 'game':
                buttonText = 'GAME';
                buttonIcon = 'gamepad';
                break;
            case 'ai':
                buttonText = 'AI';
                buttonIcon = 'brain';
                break;
            default:
                buttonText = type.toUpperCase();
                buttonIcon = 'code';
        }
        
        return (
            <button className="website-btn">
                {buttonIcon === 'code' ? (
                    <span className="code-icon">&lt;/&gt;</span>
                ) : (
                    <Icon name={buttonIcon} />
                )}
                {buttonText}
            </button>
        );
    }

    function getProjectCard(item) {
        let image = "../images/hobby-projects/" + item.image;
        return (
            <div key={item.name} className="project-item">
                <div className="project-card">
                    <div className="project-header">
                        <div className="title-section">
                            <div className="project-title-container">
                                <Item.Header as='a' className="project-title">
                                    {item.name}
                                </Item.Header>
                            </div>
                            <Item.Meta className="project-subtitle">
                                {item.subName}
                            </Item.Meta>
                            {item.type && (
                                <div className="website-button-container">
                                    {getTypeLabel(item.type)}
                                </div>
                            )}
                        </div>
                        <div className="logo-container">
                            <img src={image} alt={item.name} className="project-logo" />
                        </div>
                    </div>
                    <div className="project-body">
                        {/* Website button removed from here */}
                        <div className="description-header">
                            <Icon name="lightbulb outline" size="small" style={{marginRight: '8px', opacity: 0.7}} />
                            <span>Project Description</span>
                        </div>
                        <Item.Description className="project-description">{item.description}</Item.Description>
                        
                        {(item.exlink || (item.reviews && item.reviews.length > 0)) && (
                            <div className="project-links-section">
                                <div className="links-header">
                                    <Icon name="linkify" size="small" style={{marginRight: '8px', opacity: 0.7}} />
                                    <span>External Links</span>
                                </div>
                                <ul className="external-links-list">
                                    {item.exlink && (
                                        <li>
                                            <span className="link-site-name">{item.exlinkname}</span> 
                                            <a href={item.exlink} target="_blank" rel="noreferrer" className="link-action">
                                                Click to {item.exlinkname.toLowerCase().includes('video') ? 'Watch' : 'Read'}
                                            </a>
                                        </li>
                                    )}
                                    
                                    {item.reviews && item.reviews.map(review => (
                                        <li key={review.name}>
                                            <span className="link-site-name">{review.name}</span> 
                                            <a href={review.exlink} target="_blank" rel="noreferrer" className="link-action">
                                                Click to {review.name.toLowerCase().includes('video') ? 'Watch' : 'Read'}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <style jsx="true">{`
                    .project-card {
                        position: relative;
                        color: #343434;
                        background: #f9f9f9;
                        border-radius: 12px;
                        padding: 20px;
                        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
                        transition: transform 0.2s, box-shadow 0.2s;
                        height: 100%;
                        overflow: hidden;
                        border-left: 4px solid #e74c3c;
                    }
                    
                    .project-card:hover {
                        transform: translateY(-3px);
                        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
                    }
                    
                    .project-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        margin-bottom: 15px;
                        position: relative;
                    }
                    
                    .title-section {
                        flex: 1;
                    }
                    
                    .project-title-container {
                        margin-bottom: 5px;
                    }
                    
                    .project-title {
                        font-weight: bold !important;
                        color: #2c3e50 !important;
                        font-size: 1.3em !important;
                        margin-bottom: 0 !important;
                        display: inline-block !important;
                    }
                    
                    .project-subtitle {
                        color: #7f8c8d !important;
                        font-style: italic;
                        margin-bottom: 8px !important;
                    }
                    
                    .project-type {
                        margin-top: 5px;
                    }
                    
                    .project-type-label {
                        font-size: 0.85em !important;
                    }
                    
                    .logo-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 20px;
                    }
                    
                    .project-logo {
                        width: 80px;
                        border-radius: 8px;
                        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                        transition: transform 0.3s;
                        background: white;
                        padding: 5px;
                        border: 1px solid #eaeaea;
                    }
                    
                    .project-logo:hover {
                        transform: scale(1.05);
                    }
                    
                    .project-body {
                        padding-top: 5px;
                        line-height: 1.6;
                        color: #34495e;
                    }
                    
                    .website-button-container {
                        margin: 15px 0;
                    }
                    
                    .website-btn {
                        background-color: #58595B;
                        border: none;
                        color: white;
                        padding: 6px 18px;
                        text-align: center;
                        text-decoration: none;
                        display: inline-flex;
                        align-items: center;
                        font-size: 14px;
                        margin: 4px 0;
                        cursor: pointer;
                        border-radius: 4px;
                        transition: background-color 0.3s;
                    }
                    
                    .code-icon {
                        font-family: monospace;
                        margin-right: 5px;
                        font-size: 14px;
                        font-weight: bold;
                    }
                    
                    .website-btn:hover {
                        background-color: #e0e0e0;
                    }
                    
                    .description-header, .links-header {
                        font-weight: 600;
                        color: #2c3e50;
                        margin-bottom: 8px;
                        font-size: 1em;
                        display: flex;
                        align-items: center;
                    }
                    
                    .links-header {
                        margin-top: 0;
                        font-size: 1.1em;
                        font-weight: 500;
                        color: #333;
                        margin-bottom: 5px;
                    }
                    
                    .project-description {
                        margin-bottom: 15px;
                    }
                    
                    .external-links-list {
                        list-style-type: none;
                        padding-left: 0;
                        margin-top: 12px;
                        margin-bottom: 0;
                    }
                    
                    .external-links-list li {
                        position: relative;
                        padding-left: 20px;
                        margin-bottom: 10px;
                        line-height: 1.5;
                    }
                    
                    .external-links-list li:before {
                        content: "•";
                        position: absolute;
                        left: 0;
                        color: #666;
                    }
                    
                    .link-site-name {
                        font-weight: 500;
                        color: #333;
                    }
                    
                    .link-action {
                        margin-left: 8px;
                        color: #3498db;
                        transition: color 0.2s;
                        font-size: 0.95em;
                    }
                    
                    .link-action:hover {
                        color: #2980b9;
                        text-decoration: underline;
                    }
                    
                    .project-links-section {
                        background-color: #f8f9fa;
                        border-radius: 5px;
                        border: 1px solid #dee2e6;
                        padding: 15px;
                        margin-top: 15px;
                    }
                    
                    @media (max-width: 768px) {
                        .project-header {
                            flex-direction: column-reverse;
                            align-items: flex-start;
                        }
                        
                        .logo-container {
                            align-self: flex-end;
                            margin-bottom: 10px;
                            margin-left: 0;
                            position: absolute;
                            top: 0;
                            right: 10px;
                        }
                        
                        .project-logo {
                            width: 60px;
                        }
                        
                        .title-section {
                            width: 100%;
                            padding-right: 80px;
                        }
                        
                        .project-links {
                            gap: 8px;
                        }
                    }
                    
                    .project-item {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                    }
                    
                    .project-card {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                    }
                    
                    .project-body {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                    }
                    
                    .project-description {
                        flex: 1;
                    }
                    
                    @media (max-width: 480px) {
                        .project-card {
                            padding: 15px 20px 15px 15px;
                        }
                        
                        .project-logo {
                            width: 50px;
                        }
                        
                        .logo-container {
                            right: 15px;
                        }
                        
                        .title-section {
                            padding-right: 70px;
                        }
                        
                        .project-title {
                            font-size: 1.1em !important;
                        }
                        
                        .project-links-section {
                            padding: 12px 15px;
                        }
                        
                        .external-links-list li {
                            padding-left: 15px;
                            margin-bottom: 8px;
                        }
                        
                        .link-site-name {
                            display: block;
                            margin-bottom: 2px;
                        }
                        
                        .link-action {
                            margin-left: 0;
                            font-size: 0.9em;
                        }
                        
                        .website-btn {
                            padding: 6px 20px 6px 15px;
                            margin-right: 10px;
                        }
                    }
                `}</style>
            </div>
        )
    }

    return (
        <div id="main">
            <section>
                <div className="section-header">
                    <Header
                        as='h2'
                        content='Hobby Projects'
                        subheader='Creative endeavors built on passion and curiosity'
                        className="projects-title"
                    />
                </div>
                <div className="projects-showcase">
                    <div className="projects-grid">
                        {data && data.projects && data.projects.map(item => (
                            getProjectCard(item)
                        ))}
                    </div>
                </div>
                <style jsx="true">{`
                    .section-header {
                        margin-bottom: 30px;
                        position: relative;
                    }
                    
                    .projects-title h2 {
                        color: #2c3e50 !important;
                        font-size: 2em !important;
                        font-weight: 600 !important;
                    }
                    
                    .projects-title .sub.header {
                        color: #7f8c8d !important;
                        font-size: 0.85em !important;
                        margin-top: 5px !important;
                        font-weight: normal !important;
                    }
                    
                    .projects-showcase {
                        position: relative;
                    }
                    
                    .projects-grid {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 20px;
                    }
                    
                    @media (max-width: 1024px) {
                        .projects-grid {
                            grid-template-columns: 1fr;
                            gap: 15px;
                        }
                    }
                    
                    @media (max-width: 768px) {
                        .projects-showcase {
                            padding-left: 0;
                        }
                    }
                `}</style>
            </section>
        </div>
    );
}
