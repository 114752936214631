import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import { formatText } from "../utils/Utils";

function MobileTopBar() {
  const [randomImage, setRandomImage] = useState("");
  
  useEffect(() => {
    setRandomImage(getRandomImage());
  }, []);
  
  function getRandomImage() {
    let rand = Math.floor(Math.random() * 10);
    let imageNum = rand + 1;
    return `/images/amith_gc${imageNum}.png`;
  }
  
  return (
    <div className="mobile-top-bar">
      <div className="mobile-info">
        <div className="mobile-name">Amith Chandrappa</div>
        <div className="mobile-title">
          Engineering Head at <a href="https://www.give.do/" target="_blank" rel="noreferrer">GIVE</a>
        </div>
      </div>
      <div className="mobile-avatar-container">
        <img 
          src={randomImage || "/images/amith_gc1.png"} 
          alt="Amith GC" 
          className="mobile-avatar"
          onError={(e) => {
            console.error("Mobile image failed to load", e);
            e.target.src = "/images/avatar.jpg";
          }}
        />
      </div>
    </div>
  );
}

export function StoriesList() {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isMobile, setIsMobile] = useState(false);
  
  // Check if we're on mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile(); // Check on initial load
    window.addEventListener('resize', checkMobile);
    
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  React.useEffect(() => {
    getData();
  }, []);

  function getData() {
    setIsLoading(true);
    fetch('https://stories.amithgc.com/imagessitemap.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error("Error fetching stories data:", error);
        setIsLoading(false);
      });
  }

  function formatDate(inputDate) {
    const months = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];

    const dateParts = inputDate.split(" ");
    const dateString = dateParts[0];

    const [year, month, day] = dateString.split("-").map(part => parseInt(part));
    const formattedDate = `${day} ${months[month - 1]}, ${year}`;

    return formattedDate;
  }

  function getStoryCard(item) {
    let image = item.img;
    let date = formatDate(item.date);
    let link = item.location;

    return (
      <div className="story-card" key={item.title}>
        <div className="story-card-inner">
          <div className="story-image">
            <img src={image} alt={item.title} />
          </div>
          <div className="story-content">
            <h3 className="story-title">
              <a href={link} target="_blank" rel="noreferrer">{formatText(item.title)}</a>
            </h3>
            <p className="story-summary">{formatText(item.summary)}</p>
            <div className="story-footer">
              <div className="story-date">
                <Icon name="calendar alternate outline" />
                <span>{date}</span>
              </div>
              <a href={link} target="_blank" rel="noreferrer" className="read-more">
                Read More <Icon name="arrow right" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="main">
      {isMobile && <MobileTopBar />}
      <section>
        <div className="section-header">
          <Header
            as="h2"
            content="Automated Children Stories [🧒👧]"
            subheader="My kids were craving stories that would transport them to faraway lands and spark their imaginations. So, I conjured up something truly remarkable—an automated website that hosts captivating tales just for them! Here are few of the Stories which are generated using GPT."
            className="stories-title"
          />
        </div>

        {isLoading ? (
          <div className="loading">
            <Icon name="spinner" loading size="large" />
            <span>Loading stories...</span>
          </div>
        ) : (
          <>
            <div className="stories-grid">
              {data && data.map(item => (
                getStoryCard(item)
              ))}
            </div>

            <div className="view-all-container">
              <a
                href="https://stories.amithgc.com"
                target="_blank"
                rel="noreferrer"
                className="view-all-button"
              >
                View All Stories <Icon name="arrow right" />
              </a>
            </div>
          </>
        )}

        <style jsx="true">{`
          .section-header {
            margin-bottom: 30px;
            position: relative;
          }
          
          .stories-title h2 {
            color: #2c3e50 !important;
            font-size: 2em !important;
            font-weight: 600 !important;
          }
          
          .stories-title .sub.header {
            color: #7f8c8d !important;
            font-size: 0.85em !important;
            margin-top: 5px !important;
            font-weight: normal !important;
          }
          
          .loading {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 40px;
            flex-direction: column;
            gap: 15px;
            color: #555;
          }
          
          .stories-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 25px;
            margin-bottom: 30px;
          }
          
          .story-card {
            border-radius: 12px;
            overflow: hidden;
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
            transition: transform 0.25s ease, box-shadow 0.25s ease;
            background: white;
            height: 100%;
          }
          
          .story-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 10px 25px rgba(0, 0, 0, 0.12);
          }
          
          .story-card-inner {
            display: flex;
            flex-direction: column;
            height: 100%;
          }
          
          .story-image {
            height: 200px;
            overflow: hidden;
            position: relative;
          }
          
          .story-image img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.5s ease;
          }
          
          .story-card:hover .story-image img {
            transform: scale(1.05);
          }
          
          .story-content {
            padding: 20px;
            display: flex;
            flex-direction: column;
            flex: 1;
          }
          
          .story-title {
            margin-top: 0;
            margin-bottom: 12px;
            font-size: 1.25em;
            font-weight: 600;
            line-height: 1.4;
          }
          
          .story-title a {
            color: #2c3e50;
            text-decoration: none;
            transition: color 0.2s;
          }
          
          .story-title a:hover {
            color: #3498db;
          }
          
          .story-summary {
            color: #5f6368;
            font-size: 0.95em;
            line-height: 1.6;
            margin-bottom: 15px;
            flex: 1;
          }
          
          .story-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: auto;
            border-top: 1px solid #eee;
            padding-top: 15px;
          }
          
          .story-date {
            font-size: 0.85em;
            color: #7f8c8d;
            display: flex;
            align-items: center;
            line-height: 1;
          }
          
          .story-date i {
            margin-right: 5px;
            display: flex;
            align-items: center;
            line-height: 1;
          }
          
          .read-more {
            font-size: 0.9em;
            color: #3498db;
            display: flex;
            align-items: center;
            transition: color 0.2s;
          }
          
          .read-more:hover {
            color: #2980b9;
          }
          
          .read-more i {
            margin-left: 5px;
            font-size: 0.85em;
            display: flex;
            align-items: center;
            line-height: 1;
          }
          
          .view-all-button i {
            margin-left: 8px;
            display: flex;
            align-items: center;
            line-height: 1;
          }
          
          .view-all-container {
            text-align: center;
            margin-top: 10px;
            margin-bottom: 30px;
          }
          
          .view-all-button {
            display: inline-flex;
            align-items: center;
            background-color: #3498db;
            color: white;
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 0.95em;
            transition: background-color 0.2s, transform 0.2s;
          }
          
          .view-all-button:hover {
            background-color: #2980b9;
            transform: translateY(-2px);
            color: white;
          }
          
          @media (max-width: 1024px) {
            .stories-grid {
              grid-template-columns: 1fr;
              gap: 20px;
            }
          }
          
          @media (max-width: 768px) {
            .story-image {
              height: 180px;
            }
            
            .story-content {
              padding: 15px;
            }
            
            .story-title {
              font-size: 1.2em;
            }
          }
          
          @media (max-width: 480px) {
            .story-image {
              height: 160px;
            }
            
            .story-content {
              padding: 12px 15px;
            }
            
            .story-title {
              font-size: 1.1em;
            }
            
            .story-footer {
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;
            }
            
            .read-more {
              margin-left: auto;
              margin-right: auto;
            }
          }
          
          /* Mobile Top Bar Styles */
          .mobile-top-bar {
            display: none;
            background: linear-gradient(135deg, #1a2a3a, #152536, #0e1c2d);
            color: white;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 65px;
            padding: 0 20px;
            z-index: 900;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          
          .mobile-avatar-container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
          
          .mobile-avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            border: 2px solid rgba(78, 204, 163, 0.8);
            margin-right: 10px;
          }
          
          .mobile-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          
          .mobile-name {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.5px;
            color: white;
            margin-bottom: 0px;
            line-height: 1.2;
          }
          
          .mobile-title {
            font-size: 12px;
            color: rgba(78, 204, 163, 0.9);
            letter-spacing: 0.4px;
            line-height: 1.2;
          }
          
          .mobile-title a {
            color: rgba(255, 255, 255, 0.95);
            border-bottom: 1px dotted rgba(78, 204, 163, 0.7);
            text-decoration: none;
            transition: all 0.2s ease;
            padding-bottom: 1px;
          }
          
          .mobile-title a:hover {
            color: white;
            border-bottom-color: rgba(78, 204, 163, 1);
          }
          
          @media (max-width: 768px) {
            .mobile-top-bar {
              display: flex;
            }
            
            /* Adjust the main content padding to account for the top bar */
            #main {
              padding-top: 80px !important;
            }
          }
        `}</style>
      </section>
    </div>
  );
}
