import React from "react";
import {Item, Label, Icon} from 'semantic-ui-react'
import Header from "semantic-ui-react/dist/commonjs/elements/Header";

export function WorkExperience() {

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        getData()
    }, []);

    function getData() {
        fetch('data/experience.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson)
            });
    }
    
    function getExperienceCard(item, index) {
        let image = "../images/companies/" + item.image;
        let exp = `${item.from} - ${item.to}`;
        return (
            <Item key={item.name}>
                <div className="experience-card">
                    <div className="experience-header">
                        <div className="title-section">
                            <div className="position-container">
                                <Item.Header as='a' className="position-title">
                                    {item.designation}
                                </Item.Header>
                                <Item.Meta className="company-container">
                                    <span className="at-symbol">at</span>
                                    <span className='company-name'>{item.name}</span>
                                </Item.Meta>
                            </div>
                            <Label icon='calendar' content={exp} className="date-label"/>
                        </div>
                        <div className="logo-container">
                            <img src={image} alt={item.name} className="company-logo" />
                        </div>
                    </div>
                    <div className="experience-body">
                        <div className="description-header">
                            <Icon name="tasks" size="small" style={{marginRight: '8px', opacity: 0.7}} />
                            <span>Responsibilities & Achievements</span>
                        </div>
                        <Item.Description>{item.description}</Item.Description>
                    </div>
                </div>
                <style jsx="true">{`
                    .experience-card {
                        position: relative;
                        color: #343434;
                        background: #f9f9f9;
                        border-radius: 12px;
                        padding: 20px;
                        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
                        transition: transform 0.2s, box-shadow 0.2s;
                        margin-bottom: 25px;
                        overflow: hidden;
                        border-left: 4px solid #49bf9d;
                    }
                    
                    .experience-card:hover {
                        transform: translateY(-3px);
                        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
                    }
                    
                    /* Card number styles removed */
                    
                    .experience-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 15px;
                        position: relative;
                    }
                    
                    .title-section {
                        flex: 1;
                    }
                    
                    .position-container {
                        margin-bottom: 8px;
                    }
                    
                    .position-title {
                        font-weight: bold !important;
                        color: #2c3e50 !important;
                        font-size: 1.3em !important;
                        margin-bottom: 0 !important;
                        display: inline !important;
                    }
                    
                    .company-container {
                        display: inline !important;
                        margin-top: 0 !important;
                        margin-left: 6px !important;
                    }
                    
                    .at-symbol {
                        color: #95a5a6;
                        font-size: 0.9em;
                        font-style: italic;
                        margin-right: 5px;
                    }
                    
                    .company-name {
                        color: #3498db;
                        font-size: 1.15em;
                        font-weight: 500;
                    }
                    
                    .date-label {
                        margin-top: 8px !important;
                        background-color: #e9f7ef !important;
                        color: #27ae60 !important;
                        font-weight: normal !important;
                    }
                    
                    .logo-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 20px;
                    }
                    
                    .company-logo {
                        width: 80px;
                        border-radius: 8px;
                        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                        transition: transform 0.3s;
                        background: white;
                        padding: 5px;
                        margin-right: 5px;
                    }
                    
                    .company-logo:hover {
                        transform: scale(1.05);
                    }
                    
                    .experience-body {
                        padding-top: 5px;
                        line-height: 1.6;
                        color: #34495e;
                    }
                    
                    .description-header {
                        font-weight: 600;
                        color: #2c3e50;
                        margin-bottom: 8px;
                        font-size: 1em;
                        display: flex;
                        align-items: center;
                    }
                    
                    @media (max-width: 768px) {
                        .experience-header {
                            flex-direction: column-reverse;
                            align-items: flex-start;
                        }
                        
                        .logo-container {
                            align-self: flex-end;
                            margin-bottom: 10px;
                            margin-left: 0;
                            position: absolute;
                            top: 0;
                            right: 10px;
                        }
                        
                        .company-logo {
                            width: 60px;
                            margin-right: 0;
                        }
                        
                        .title-section {
                            width: 100%;
                            padding-right: 80px;
                        }
                        
                        .position-container {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        }
                        
                        .position-title {
                            display: block !important;
                            margin-bottom: 5px !important;
                        }
                        
                        .company-container {
                            display: block !important;
                            margin-left: 0 !important;
                        }
                    }
                    
                    @media (max-width: 480px) {
                        .experience-card {
                            padding: 15px;
                        }
                        
                        .company-logo {
                            width: 50px;
                        }
                        
                        .logo-container {
                            right: 15px;
                        }
                        
                        .title-section {
                            padding-right: 70px;
                        }
                        
                        .position-title {
                            font-size: 1.1em !important;
                        }
                        
                        .company-name {
                            font-size: 1em;
                        }
                    }
                `}</style>
            </Item>
        )
    }

    return (
        <div id="main">
            <section>
                <div className="section-header">
                    <Header
                        as='h2'
                        content='Work Experience'
                        subheader='List of all the companies I have worked in'
                        className="experience-title"
                    />
                </div>
                <div className="experience-timeline">
                    <Item.Group>
                        {data && data.experiences && data.experiences.map((item, index) => (
                            getExperienceCard(item, index)
                        ))}
                    </Item.Group>
                </div>
                <style jsx="true">{`
                    .section-header {
                        margin-bottom: 30px;
                        position: relative;
                    }
                    
                    .experience-title h2 {
                        color: #2c3e50 !important;
                        font-size: 2em !important;
                        font-weight: 600 !important;
                    }
                    
                    .experience-title .sub.header {
                        color: #7f8c8d !important;
                        font-size: 0.85em !important;
                        margin-top: 5px !important;
                        font-weight: normal !important;
                    }
                    
                    .experience-timeline {
                        position: relative;
                    }
                    
                    @media (max-width: 768px) {
                        .experience-timeline {
                            padding-left: 0;
                        }
                    }
                `}</style>
            </section>
        </div>
    );
}
